import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null, hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo, hasError: true })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  shouldComponentUpdate(newProps, newState) {
    return (newProps.children !== this.props.children || newState.error !== this.state.error)
  }

  render() {
    const currentState = this.state
    if (currentState.error) {
      return (
        <>
          <section className='section container'>
            <h1 className='title has-text-centered has-text-light'>NOT FOUND</h1>
            <hr />
            <p className='has-text-centered'>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </section>
        </>
      )
    } else {
      return this.props.children
    }
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
